import moment from 'moment';

import PropTypes from 'prop-types';

import React from 'react';
import { useEffect, useState } from 'react';
import { formatPhoneNumber } from 'react-phone-number-input';
import { connect } from 'react-redux';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

import { createStructuredSelector } from 'reselect';

import ActionButton from 'atoms/ActionButton';
import PdfViewer from 'atoms/PdfViewer/index.js';
import ImageDetailsCell from 'atoms/TableCell/ImageDetailsCell';
import classnames from 'classnames';
import AddClientToGroup from 'components/AddClientToGroup';
import AttendanceRecord from 'components/AttendanceRecord/index';
import LoadingModal from 'components/LoadingModal';
import PatientCareGroup from 'components/PatientCareGroup';
import PatientDemographic from 'components/PatientDemographic';
import PatientProfileEditModal from 'components/PatientProfileEditModal';
import PatientRequestModal from 'components/PatientRequestModal/index.js';
import PatientTherapies from 'components/PatientTherapies';
import PatientTherapiesHistory from 'components/PatientTherapiesHistory/index.js';
import StatusModal from 'components/RequestSentModal';
import UpdateEnrollmentModal from 'components/UpdateEnrollmentModal';
import UserDocuments from 'components/UserDocuments';
import {
	fetchCities,
	fetchStates,
	fetchZipcodes,
	generateToast,
	viewPdf,
} from 'containers/App/actions';
import { loadClinicians } from 'containers/App/actions';
import { formsTypesShouldHavePdf } from 'containers/App/constants.js';
import {
	makeSelectAuth,
	makeSelectCities,
	makeSelectFile,
	makeSelectStates,
	makeSelectZipcodes,
} from 'containers/App/selectors';
import { makeSelectClinicians } from 'containers/App/selectors';
import useIsMobile from 'hooks/isMobile.ts';
import editImage from 'svgs/edit';
import hideImage from 'svgs/hide.js';
import showImage from 'svgs/show.js';

import {
	addClientToGroup,
	clearProfile,
	fetchGroups,
	fetchPatientAttendance,
	fetchPatientDocs,
	fetchPatientGroups,
	fetchPatientProfile,
	getSessions,
	updateEnrollment,
	updatePatientInfo,
} from './actions.js';
import {
	makeSelectAllGroups,
	makeSelectError,
	makeSelectGetSessions,
	makeSelectIsUpdating,
	makeSelectLoadingAttendance,
	makeSelectLoadingDocs,
	makeSelectLoadingGroups,
	makeSelectLoadingInfo,
	makeSelectRequests,
	makeSelectUpdateError,
	makeSelectUpdateSuccess,
	makeSelectUserAttendance,
	makeSelectUserDocs,
	makeSelectUserError,
	makeSelectUserGroups,
	makeSelectUserInfo,
} from './selectors';

const demoTabs = [
	{
		name: 'Demographic',
		id: 'demographic',
	},
	{
		name: 'Care Providers',
		id: 'careProviders',
	},
];

const detailsTabs = [
	{
		name: 'Attendance Calendar',
		id: 'attendance',
	},
	{
		name: 'Enrolled Therapies',
		id: 'enrolledTherapies',
	},
	{
		name: 'Unenrolled Therapies',
		id: 'unenrolledTherapies',
	},
	{
		name: 'Documents and Forms',
		id: 'documentsAndForms',
	},
	/* 
  {
    name: 'History',
    id: 'history',
  }, */
];

const PatientProfile = (props) => {
	const {
		routeProps,
		type,
		loadPatientProfile,
		loadPatientGroups,
		loadPatientAttendance,
		loadSessions,
		loadClinicians,
		loadPatientDocs,
		clearUserProfile,
		user,
		groups,
		allGroups,
		docs,
		userError,
		loadingDocs,
		sessions,
		loadStates,
		loadCities,
		loadZipcodes,
		attendance,
		cities,
		states,
		zipcodes,
		clinicians,
		loadingInfo,
		isUpdating,
		updateError,
		updateSuccess,
		doAddClientToGroup,
		dispatch,
		requests,
		file,
		auth,
	} = props;

	const [activeDemoTab, setActiveDemoTab] = useState('demographic');
	const [activeDetailsTab, setActiveDetailsTab] = useState('attendance');
	const [reload, setReload] = useState('all');
	const [showEditProfile, setShowEditProfile] = useState(false);
	const [showUpdateEnrollment, setShowUpdateEnrollment] = useState(false);
	const [updateRequestSent, setUpdateRequestSent] = useState(false);
	const [programsToUpdate, setProgramsToUpdate] = useState([]);
	const [enrolledGroups, setEnrolledGroups] = useState([]);
	const [unenrolledGroups, setUnEnrolledGroups] = useState([]);
	const [showPdfViewer, setShowPdfViewer] = useState(false);
	const [activeFile, setActiveFile] = useState(null);
	const [isLoadingFile, setIsLoadingFile] = useState(false);
	const [showRequest, setShowRequest] = useState(false);
	const [id, setId] = useState(null);

	const [showDemographic, setShowDemographic] = useState(true);
	const [showAddGroup, setShowAddGroup] = useState(false);

	const isMobile = useIsMobile();

	const reloadMap = {
		profile: loadPatientProfile,
		groups: loadPatientGroups,
		attendance: loadPatientAttendance,
		sessions: loadSessions,
		clinicians: loadClinicians,
	};

	useEffect(() => {
		const pathname = routeProps.location.pathname;
		const split = pathname.split('/');
		setId(split[split.length - 1]);

		return () => {
			clearUserProfile();
		};
	}, [routeProps.location.pathname]);

	useEffect(() => {
		if (!parseInt(id)) return;

		if (reload === 'all') {
			loadPatientProfile(id);
			loadPatientGroups(id);
			loadPatientAttendance(id);
			loadSessions(id);
			loadPatientDocs(id);
			loadClinicians();
		} else if (Array.isArray(reload)) {
			reload.forEach((type) => reloadMap[type](id));
		}
	}, [reload, id]);

	useEffect(() => {
		if (!Array.isArray(groups)) return;

		setEnrolledGroups(
			groups
				.filter((g) => !g.endDate || moment(g.endDate).isAfter(moment()))
				.sort((a, b) => customSort(a, b)),
		);
		setUnEnrolledGroups(
			groups
				.filter((g) => g.endDate && moment(g.endDate).isBefore(moment()))
				.sort((a, b) => customSort(a, b)),
		);
	}, [groups]);

	const customSort = (group1, group2) => {
		const date1 = moment(group1.repeatsOn[0], 'dddd').weekday();
		const date2 = moment(group2.repeatsOn[0], 'dddd').weekday();

		if (date1 < date2) {
			return -1;
		} else if (date1 > date2) {
			return 1;
		} else {
			if (
				moment(group1.startTime, 'h:mma').isBefore(
					moment(group2.startTime, 'h:mma'),
				)
			) {
				return -1;
			} else {
				return 1;
			}
		}
	};

	const toggleTab = (type) => (tab) => {
		if (type === 'demo') {
			if (activeDemoTab !== tab) {
				setActiveDemoTab(tab);
			}
		} else if (type === 'details') {
			if (activeDetailsTab !== tab) {
				setActiveDetailsTab(tab);
			}
		}
	};

	const toggleEditProfile = () => {
		if (!showEditProfile) loadStates();

		setShowEditProfile(!showEditProfile);
	};

	const toggleUpdateEnrollment = () => {
		setShowUpdateEnrollment(!showUpdateEnrollment);
	};

	const updatePatientInfo = (data) => {
		const { auth, updatePatient } = props;
		const payload = {};

		let patientId = parseInt(id);
		payload.id = patientId;
		payload.senderUserId = auth.loggedInUser.id;
		payload.data = data;

		updatePatient(payload, () => setReload(['profile']));
		setUpdateRequestSent(true);
	};

	const updateEnrollment = (ids) => {
		const programsToUpdate = groups.filter((g) => ids.includes(g.ptpId));
		setProgramsToUpdate(programsToUpdate);
		setShowUpdateEnrollment(true);
	};

	const doUpdateEnrollment = (startDates, endDates) => {
		const { updateEnrollment } = props;
		const data = {
			patientId: id,
			// program ids no longer needed, should remove these values
			programIds: programsToUpdate.map((p) => p.program_id),
			startDates,
			endDates,
			ptpIds: programsToUpdate.map((p) => p.ptpId), // use these to update
		};

		updateEnrollment(data);
		setShowUpdateEnrollment(false);
		setUpdateRequestSent(true);
		setProgramsToUpdate([]);
	};

	const closeRequestSentModal = () => {
		setUpdateRequestSent(false);
		setShowEditProfile(false);
	};

	const handleViewForm = (data) => {
		const { documentType } = data;

		setActiveFile(data);

		if (formsTypesShouldHavePdf.includes(documentType.toLowerCase())) {
			setShowPdfViewer(true);
			setIsLoadingFile(true);
			dispatch(
				viewPdf(
					data.uuid,
					() => setIsLoadingFile(false),
					() => setIsLoadingFile(false),
				),
			);
		} else {
			setShowRequest(true);
		}
	};

	const toggleShowRequest = () => {
		setShowRequest(!showRequest);
	};

	const togglePdfViewer = () => {
		if (showPdfViewer) {
			setActiveFile(null);
		}

		setShowPdfViewer(!showPdfViewer);
	};

	const toggleAddGroup = () => {
		const { loadAllGroups } = props;
		loadAllGroups();
		setShowAddGroup(!showAddGroup);
	};

	const addClientToGroup = (data) => {
		doAddClientToGroup(data, () => {
			dispatch(
				generateToast({
					text: 'Client Added to Group',
					type: 'custom-success',
				}),
			);
			loadPatientGroups(id);
		});
	};

	const filterAllGroups = () => {
		const filteredGroups = allGroups.filter((ag) => {
			const index = enrolledGroups.findIndex((g) => {
				return g.program_id === ag.id;
			});

			return index < 0;
		});
		return filteredGroups;
	};

	let formattedPhone =
		user.phone &&
		(user.phone.length === 10
			? formatPhoneNumber(`+1${user.phone}`)
			: formatPhoneNumber(user.phone));

	return (
		<div className="mt-1">
			{userError ? (
				<div className="d-flex bg-white px-5 py-4 mx-5 my-3 text-center justify-content-center">
					User does not exist
				</div>
			) : (
				<div>
					{updateRequestSent && (updateError || updateSuccess) && (
						<StatusModal
							successStatus={!updateError}
							hide={closeRequestSentModal}
							message={
								updateError
									? 'Something went wrong. Please contact an administrator'
									: 'Client saved successfully'
							}
						/>
					)}
					<div className="container-fluid bg-white px-5 py-4">
						<div className="row py-3 bg-white p-0">
							<div className="w-100 d-flex justify-content-center">
								<div className="mr-3">
									<ImageDetailsCell
										src={user.profilePicture || ''}
										heading={user.name}
										showDetails={false}
										width="140"
										className=""
									/>
								</div>
								<div className="h-100">
									<h3 className="text-dark mb-3 pb-3 font-weight-bold border-bottom">
										{user.name}
									</h3>
									<div className="m-0 font-12">{user.clientType}</div>
									<div className="m-0 font-12">{formattedPhone}</div>
									<div className="m-0 font-12">{user.email}</div>
								</div>
							</div>
						</div>
					</div>
					<div className="container-fluid my-2 px-5">
						<div className="row">
							<div className="col-auto text-uppercase font-weight-bold font-11 py-2 mb-1 d-flex justify-content-between">
								Demographic{' '}
								<span
									className={'mx-2 hand'}
									onClick={() => setShowDemographic(!showDemographic)}
									style={{
										marginTop: -1,
									}}
								>
									{showDemographic ? showImage : hideImage}
								</span>
							</div>
						</div>
						<div className="row justify-content-between pb-4">
							<div
								className={`col-auto p-0 mr-${showDemographic ? 5 : 0}`}
								style={{
									width: showDemographic ? 484 : 0,
									height: showDemographic ? 'auto' : 30,
									transition: '0.5s width ease-in-out',
								}}
							>
								{showDemographic && (
									<>
										<Nav
											tabs
											className="actions m-0 mb-2 border-bottom-0 shadow-none"
											style={{ position: 'relative' }}
										>
											{demoTabs.map((value) => (
												<NavItem key={value.id}>
													<NavLink
														className={classnames(
															{
																active: activeDemoTab === value.id.toString(),
															},
															'font-13 hand',
														)}
														onClick={() => {
															toggleTab('demo')(value.id.toString());
														}}
													>
														{value.name}
													</NavLink>
												</NavItem>
											))}
											<div
												style={{
													zIndex: '2',
													position: 'absolute',
													right: '0.5rem',
													top: '50%',
													transform: 'translateY(-50%)',
												}}
											>
												{type !== 'patient' && (
													<ActionButton
														image={editImage}
														onClick={toggleEditProfile}
														alt="Edit Patient Demographic"
														style={{
															transform: 'translate(38%, -100%)',
														}}
													/>
												)}
											</div>
										</Nav>
										<TabContent activeTab={activeDemoTab}>
											<TabPane tabId="demographic">
												<div className="row">
													<div className="col-sm-12">
														<PatientDemographic
															data={user}
															toggleEdit={toggleEditProfile}
															type={type}
														/>
													</div>
												</div>
											</TabPane>
											<TabPane tabId="careProviders">
												<div className="row">
													<div className="col-sm-12">
														<PatientCareGroup
															data={user.careGroup}
															mainClinician={user.mainClinician}
															otherPhysicians={user.otherPhysicians}
														/>
													</div>
												</div>
											</TabPane>
										</TabContent>
									</>
								)}
							</div>
							<div className="col p-0" style={{ minWidth: '480px' }}>
								<Nav
									tabs
									className="actions m-0 mb-2 border-bottom-0 shadow-none"
									style={{
										display: 'block',
										overflow: 'auto hidden',
										whiteSpace: 'nowrap',
									}}
								>
									{detailsTabs.map((value) => (
										<NavItem key={value.id} style={{ display: 'inline-block' }}>
											<NavLink
												className={classnames(
													{
														active: activeDetailsTab === value.id.toString(),
													},
													'font-13 hand',
												)}
												onClick={() => {
													toggleTab('details')(value.id.toString());
												}}
											>
												{value.name}
											</NavLink>
										</NavItem>
									))}
								</Nav>
								<TabContent activeTab={activeDetailsTab}>
									<TabPane tabId="enrolledTherapies">
										<div className="row">
											<div className="col-sm-12">
												<PatientTherapies
													data={enrolledGroups}
													updateEnrollment={updateEnrollment}
													type={type}
													toggleAddGroup={toggleAddGroup}
												/>
											</div>
										</div>
									</TabPane>
									<TabPane tabId="unenrolledTherapies">
										<div className="row">
											<div className="col-sm-12">
												<PatientTherapiesHistory
													data={unenrolledGroups}
													updateEnrollment={updateEnrollment}
													type={type}
													toggleAddGroup={toggleAddGroup}
												/>
											</div>
										</div>
									</TabPane>
									<TabPane tabId="documentsAndForms">
										<div className="row">
											<div className="col-sm-12">
												<UserDocuments
													docs={docs}
													requests={requests}
													loading={loadingDocs}
													viewForm={handleViewForm}
													type={type}
												/>
											</div>
										</div>
									</TabPane>
									<TabPane tabId="attendance">
										<div className="row">
											<div className="col-sm-12">
												<AttendanceRecord
													attendance={attendance}
													programs={groups}
													sessions={sessions}
													type={type}
												/>
											</div>
										</div>
									</TabPane>
									{/* <TabPane tabId="history">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="container-fluid bg-white">
                          Patient History
                        </div>
                      </div>
                    </div>
                  </TabPane> */}
								</TabContent>
							</div>
						</div>
					</div>
				</div>
			)}
			{showEditProfile && (
				<PatientProfileEditModal
					toggle={toggleEditProfile}
					isOpen={showEditProfile}
					data={user}
					fetchCities={loadCities}
					fetchZipcodes={loadZipcodes}
					cities={cities}
					states={states}
					zipcodes={zipcodes}
					clinicians={clinicians}
					doSubmit={updatePatientInfo}
				/>
			)}
			{isUpdating && <LoadingModal isOpen={isUpdating} />}
			{showUpdateEnrollment && (
				<UpdateEnrollmentModal
					isOpen={showUpdateEnrollment}
					toggle={toggleUpdateEnrollment}
					programs={programsToUpdate}
					client={user}
					onSubmit={doUpdateEnrollment}
					multi
				/>
			)}
			{showAddGroup && (
				<AddClientToGroup
					client={user}
					addNewClient={addClientToGroup}
					programs={filterAllGroups()}
					hide={toggleAddGroup}
					mode="multi"
				/>
			)}
			{showPdfViewer && (
				<PdfViewer
					isOpen={showPdfViewer}
					toggle={togglePdfViewer}
					file={file}
					documentData={activeFile}
					isLoading={isLoadingFile}
				/>
			)}
			{showRequest && (
				<PatientRequestModal
					hide={toggleShowRequest}
					requestType={activeFile.documentType}
					data={activeFile.payload}
					auth={auth}
					isMobile={isMobile}
				/>
			)}
			{loadingInfo && <LoadingModal isOpen={loadingInfo} />}
		</div>
	);
};

PatientProfile.propTypes = {
	auth: PropTypes.object,
	dispatch: PropTypes.func.isRequired,
	loadingInfo: PropTypes.bool,
	loadingGroups: PropTypes.bool,
	loadingAttendance: PropTypes.bool,
	loadingDocs: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
	auth: makeSelectAuth(),
	user: makeSelectUserInfo(),
	loadingInfo: makeSelectLoadingInfo(),
	isUpdating: makeSelectIsUpdating(),
	groups: makeSelectUserGroups(),
	allGroups: makeSelectAllGroups(),
	loadingGroups: makeSelectLoadingGroups(),
	attendance: makeSelectUserAttendance(),
	loadingAttendance: makeSelectLoadingAttendance(),
	sessions: makeSelectGetSessions(),
	docs: makeSelectUserDocs(),
	requests: makeSelectRequests(),
	loadingDocs: makeSelectLoadingDocs(),
	userError: makeSelectUserError(),
	error: makeSelectError(),
	cities: makeSelectCities(),
	states: makeSelectStates(),
	zipcodes: makeSelectZipcodes(),
	clinicians: makeSelectClinicians(),
	updateError: makeSelectUpdateError(),
	updateSuccess: makeSelectUpdateSuccess(),
	file: makeSelectFile(),
});

function mapDispatchToProps(dispatch) {
	return {
		dispatch,
		loadPatientProfile: (data) => dispatch(fetchPatientProfile(data)),
		loadPatientGroups: (id) => dispatch(fetchPatientGroups(id)),
		loadAllGroups: () => dispatch(fetchGroups()),
		loadPatientDocs: (id) => dispatch(fetchPatientDocs(id)),
		loadPatientAttendance: (id) => dispatch(fetchPatientAttendance(id)),
		loadClinicians: () => dispatch(loadClinicians()),
		loadSessions: (id) => dispatch(getSessions({ patientId: id })),
		loadCities: (state) => dispatch(fetchCities(state)),
		loadZipcodes: (city) => dispatch(fetchZipcodes(city)),
		loadStates: () => dispatch(fetchStates()),
		updatePatient: (data, cb) => dispatch(updatePatientInfo(data, cb)),
		updateEnrollment: (data) => dispatch(updateEnrollment(data)),
		clearUserProfile: () => dispatch(clearProfile()),
		doAddClientToGroup: (data, cb) => dispatch(addClientToGroup(data, cb)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientProfile);
