import { uniqBy } from 'lodash';

import moment from 'moment';

import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';
import {
	ButtonDropdown,
	Col,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Row,
	TabContent,
	TabPane,
} from 'reactstrap';

import { createStructuredSelector } from 'reselect';

import NavTab from 'atoms/NavTab';
import PdfViewer from 'atoms/PdfViewer';
import SearchBar from 'atoms/SearchBar';
import FilterNav from 'components/FilterNav';
import LoadingIndicator from 'components/LoadingIndicator';
import PatientRequestModal from 'components/PatientRequestModal';
import RequestSentModal from 'components/RequestSentModal';
import StatusModal from 'components/RequestSentModal';
import SelectGroup from 'components/SelectGroup';
import SigningModal from 'components/SigningModal';
import ValidateSentModal from 'components/ValidateSentModal';
import { changeClinician, loadClinicians } from 'containers/Admin/actions';
import {
	makeSelectChangedClinician,
	makeSelectClinicians,
} from 'containers/Admin/selectors';
import {
	createRequest,
	deleteRequest,
	downloadFile,
	fetchCities,
	fetchEnrollRequests,
	fetchMemberRequests,
	fetchStates,
	fetchZipcodes,
	updateRequest,
	viewPdf,
} from 'containers/App/actions';
import {
	makeSelectAuth,
	makeSelectCities,
	makeSelectEnrollReq,
	makeSelectFile,
	makeSelectIsFetchingEnrollReq,
	makeSelectIsFetchingMemberReq,
	makeSelectIsLoadingFile,
	makeSelectIsMobile,
	makeSelectMemberReq,
	makeSelectRequestStatus,
	makeSelectStates,
	makeSelectZipcodes,
} from 'containers/App/selectors';
import EnrollmentRequests from 'containers/EnrollmentRequests';
import { MemberRequests } from 'containers/MemberRequests';
import RxRequests from 'containers/RxRequests';
import Add from 'images/add.svg';
import { compareWords } from 'utils/columnCompare';
import { isProduction } from 'utils/constants';
import { filterByLetter, filterData, getFilterOptions } from 'utils/helpers';
import { getLogger } from 'utils/logger';

import {
	clearUrl,
	fetchPharmacy,
	fetchPrograms,
	fetchRequest,
	loadPatientRequests,
	loadPatientRoiData,
	loadPatientRxRequests,
	requestTelehealth,
	signDoc,
	submitPatientRoiData,
} from './actions';
import { tabLabels } from './constants';
import requestFilters from './filters';
import './index.css';
import {
	makeSelectIsFetchingPatientGroupReq,
	makeSelectIsFetchingPatientRXReq,
	makeSelectPatientGroupRequests,
	makeSelectPharmacy,
	makeSelectPrograms,
	makeSelectRoiData,
	makeSelectRxRequests,
	makeSelectSigningUrl,
} from './selectors';

const commonLabels = [
	{
		name: 'Enrollment Requests',
		id: 1,
	},
];
const patientLabels = [
	...commonLabels,
	{
		name: 'Group Requests',
		id: 2,
	},
	{
		name: 'Rx Requests',
		id: 3,
	},
];
const adminLabels = [
	...commonLabels,
	{
		name: 'Member Requests',
		id: 4,
	},
];

const logger = getLogger('containers.Requests');

export class Requests extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			activeTab: tabLabels.ENROLLMENT_REQUESTS,
			filterClicked: true,
			showSelectGroup: false,
			loadRequestModal: false,
			dropdownOpen: false,
			dropdownItemSelected: '',
			loadRequestSentModal: false,
			enrollReq: [],
			memberReq: [],
			patientGroupRequests: [],
			patientRxRequests: [],
			modal: false,
			siteUrl: '',
			loading: false,
			signingError: false,
			filters: [],
			tabDropdownOpen: false,
			clientSearch: '',
			activeRequest: null,
			showPdfViewer: false,
			activeDocument: null,
			showDeleteRequestModal: false,
		};
		this.selectedGroupName = {};
	}

	toggle = (tab) => {
		const { dispatch } = this.props;
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab,
			});
			switch (tab) {
				case tabLabels.ENROLLMENT_REQUESTS:
					dispatch(fetchEnrollRequests());
					break;
				case tabLabels.GROUP_REQUESTS:
					dispatch(loadPatientRequests());
					break;
				case tabLabels.RX_REQUESTS:
					dispatch(loadPatientRxRequests());
					break;
				case tabLabels.MEMBER_REQUESTS:
					dispatch(fetchMemberRequests());
					break;
				default:
					break;
			}
		}
	};

	signingToggle = () => {
		const { activeRequest } = this.state;

		this.props.clearSiteUrl();
		if (activeRequest) this.props.getRequest(activeRequest);

		this.setState((prevState) => ({
			modal: !prevState.modal,
			activeRequest: null,
		}));
	};

	handleLoader = () => {
		this.setState((prevState) => ({
			loading: !prevState.loading,
		}));
	};

	downloadFile = (docId) => {
		if (!docId) return;

		const { dispatch } = this.props;
		dispatch(downloadFile(docId));
	};

	signDoc = (requestId) => {
		const { signDocument } = this.props;
		signDocument(requestId);
		this.setState({
			activeRequest: requestId,
		});
	};

	updateRequest = (id, state, payload) => {
		const { auth } = this.props;
		let data = {
			id,
			senderUserId: auth.loggedInUser.id,
		};

		// Allow for either updating state/ payload or isActive
		if (typeof state === 'string') {
			data.state = state;
			data.payload = payload;
		} else {
			data.isActive = state;
		}

		const { dispatch } = this.props;
		dispatch(updateRequest(data));
	};

	handleDeleteRequest = () => {
		const { activeRequest } = this.state;
		const { auth } = this.props;
		const data = {
			id: activeRequest,
			senderUserId: auth.loggedInUser.id,
		};

		const { dispatch } = this.props;
		dispatch(deleteRequest(data));

		this.setState(() => ({
			showDeleteRequestModal: false,
			activeRequest: null,
		}));
	};

	deleteRequest = (id) => {
		this.setState(() => ({
			showDeleteRequestModal: true,
			activeRequest: id,
		}));
	};

	componentDidMount() {
		const {
			type,
			dispatch,
			routeProps: { history, location },
		} = this.props;
		const params = new URLSearchParams(location.search);
		const requestType = params.get('requestType');

		dispatch(fetchStates());
		if (type === 'patient') {
			this.toggle(tabLabels.ENROLLMENT_REQUESTS);
			dispatch(fetchPrograms());
		}
		if (type === 'admin') {
			if (location?.state) {
				const { type = '' } = location.state;
				switch (type) {
					case 'LEAVE':
					case 'ROI':
					case 'DISCONTINUANCE':
						this.toggle(tabLabels.MEMBER_REQUESTS);
						break;
					case 'Enrollment Request':
					default:
						this.toggle(tabLabels.ENROLLMENT_REQUESTS);
						break;
				}
			} else {
				this.toggle(tabLabels.ENROLLMENT_REQUESTS);
			}
		} else if (type === 'patient') {
			if (location?.state) {
				const { type } = location.state;
				switch (type) {
					case 'LEAVE':
					case 'ROI':
					case 'DISCONTINUANCE':
						this.toggle(tabLabels.GROUP_REQUESTS);
						break;
					case 'Rx':
						this.toggle(tabLabels.RX_REQUESTS);
						break;
					case 'Enrollment Request':
					default:
						this.toggle(tabLabels.ENROLLMENT_REQUESTS);
						break;
				}
			} else {
				this.toggle(tabLabels.ENROLLMENT_REQUESTS);
			}
			if (['roi', 'loa'].includes(requestType)) {
				this.openModal(requestType);
				// Clean up the URL by removing the query params
				history.replace({
					pathname: location.pathname,
					search: '', // Remove all search params
				});
			}
		}
		dispatch(fetchEnrollRequests());
	}

	openModal(requestType) {
		// modify requestType if it comes from routeProps
		if(requestType == 'roi') {
			requestType = 'Request for Release of Information';
		}
		if(requestType == 'loa') {
			requestType = 'Request for Leave of Absence';
		}
		if (
			requestType === 'Request for Rx' ||
			requestType === 'General Request' ||
			requestType === 'Request for Leave of Absence' ||
			requestType === 'Request Telehealth' ||
			requestType === 'Request for Discontinuance'
		) {
			this.setState({
				showSelectGroup: false,
				loadRequestModal: true,
				dropdownItemSelected: requestType,
			});
		} else if (
			requestType === 'Request for Release of Information'
		) {
			const { fetchUserData, auth } = this.props;
			const { id } = auth.loggedInUser;
			fetchUserData({ id });
			this.setState({
				showSelectGroup: false,
				loadRequestModal: true,
				dropdownItemSelected: requestType,
			});
		} else {
			this.setState({
				showSelectGroup: true,
				loadRequestModal: false,
				dropdownItemSelected: requestType,
			});
		}

		if (requestType === 'Request for Rx') {
			const {
				fetchPharmacy,
				auth: {
					loggedInUser: { id },
				},
			} = this.props;
			fetchPharmacy(id);
		}
	};

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (
			nextProps.enrollReq ||
			nextProps.memberReq ||
			nextProps.patientGroupRequests ||
			nextProps.patientRxRequests
		) {
			this.setState({
				enrollReq: nextProps.enrollReq,
				memberReq: nextProps.memberReq,
				patientGroupRequests: nextProps.patientGroupRequests,
				patientRxRequests: nextProps.patientRxRequests,
			});
		}
		if (nextProps.siteUrl) {
			this.setState({
				siteUrl: nextProps.siteUrl,
				loading: false,
				modal: true,
			});
		} else if (nextProps.siteUrl === undefined) {
			this.setState({
				loading: false,
				signingError: true,
			});
		}

		if (this.props.type !== nextProps.type) {
			const activeTab = (
				nextProps.type === 'admin' ? adminLabels[0].id : patientLabels[0].id
			).toString();
			this.setState(() => ({ activeTab }));
		}
	}

	closeRequestSentModal = () => {
		this.setState({
			signingError: false,
			loadRequestSentModal: false,
		});
		this.props.clearSiteUrl();
	};

	handleChange = () => {
		this.setState((prevState) => ({ filterClicked: !prevState.filterClicked }));
	};

	selectGroup = (event) => {
		const { dropdownItemSelected } = this.state;
		if (!dropdownItemSelected) {
			this.openModal(event.target.innerText);
		}
	};

	hideSelectGroup = () => {
		this.setState({
			showSelectGroup: false,
			// dropdownItemSelected: '',
		});
	};

	handleRequestModal = () => {
		this.setState({
			loadRequestModal: true,
		});
	};

	closeRequestModal = () => {
		this.setState({
			loadRequestModal: false,
			dropdownItemSelected: '',
		});
	};

	handleRequestSentModal = (type, state, payload) => {
		const { dispatch, auth, submitRoiData } = this.props;
		const data = {
			type,
			senderUserId: auth.loggedInUser.id,
			state,
			payload,
		};
		if (type === 'ROI') {
			submitRoiData(data);
			this.setState({
				loadRequestModal: false,
				dropdownItemSelected: '',
			});
		} else if (type === 'TELEHEALTH') {
			const data = {
				type: 'telehealth',
				senderUserId: auth.loggedInUser.id,
				state: 'OPEN',
				data: {},
			};
			this.handleLoader();
			this.props.requestTelehealth(data);
			this.setState({
				loadRequestModal: false,
				dropdownItemSelected: '',
			});
		} else {
			dispatch(createRequest(data));
			this.setState({
				loadRequestSentModal: true,
				loadRequestModal: false,
				dropdownItemSelected: '',
			});
		}
	};

	handleCheck = (name) => {
		this.setState((prevState) => ({
			[name]: !prevState[name],
		}));
	};

	getRequest = (id) => {
		this.props.getRequest(id);
	};

	togglePdfViewer = () => {
		this.setState((prevState) => ({
			showPdfViewer: !prevState.showPdfViewer,
			activeFile: null,
		}));
	};

	viewPdf = (data) => {
		const { viewPdf } = this.props;
		viewPdf(data.uuid, () => logger.log('Loaded'));
		this.setState({
			showPdfViewer: true,
			activeFile: data,
		});
	};

	getTabContent = (activeTab, type) => {
		let {
			enrollReq,
			memberReq,
			patientGroupRequests,
			patientRxRequests,
			filters,
			clientSearch,
			showInactiveEnroll,
			showInactiveMember,
			showInactiveRx,
			showInactiveGroup,
		} = this.state;
		const {
			auth,
			clinicians,
			isFetchingEnrollReq,
			isFetchingMemberReq,
			isFetchingPatientGroupReq,
			isFetchingPatientRXReq,
			isMobile,
		} = this.props;

		let tabcontent;

		if (!Array.isArray(enrollReq)) {
			enrollReq = [];
		}
		if (!Array.isArray(memberReq)) {
			memberReq = [];
		}
		if (!Array.isArray(patientGroupRequests)) {
			patientGroupRequests = [];
		}
		if (!Array.isArray(patientRxRequests)) {
			patientRxRequests = [];
		}

		if (type === 'patient') {
			if (activeTab === tabLabels.ENROLLMENT_REQUESTS) {
				if (!showInactiveEnroll) {
					enrollReq = enrollReq.filter((r) => r.isActive);
				}
				filters.forEach((filter) => {
					enrollReq = filter.callback(filter.name, filter.option, enrollReq);
				});
			} else if (activeTab === tabLabels.GROUP_REQUESTS) {
				if (!showInactiveGroup) {
					showInactiveGroup = patientGroupRequests.filter((r) => r.isActive);
				}
				filters.forEach((filter) => {
					patientGroupRequests = filter.callback(
						filter.name,
						filter.option,
						patientGroupRequests,
					);
				});
			} else if (activeTab === tabLabels.RX_REQUESTS) {
				if (!showInactiveRx) {
					patientRxRequests = patientRxRequests.filter((r) => r.isActive);
				}
				filters.forEach((filter) => {
					patientRxRequests = filter.callback(
						filter.name,
						filter.option,
						patientRxRequests,
					);
				});
			}

			tabcontent = (
				<TabContent activeTab={activeTab}>
					<TabPane tabId={tabLabels.ENROLLMENT_REQUESTS}>
						<Row>
							<Col sm="12">
								<EnrollmentRequests
									data={enrollReq}
									role={type}
									downloadFile={this.downloadFile}
									auth={auth}
									loading={isFetchingEnrollReq}
									viewPdf={this.viewPdf}
								/>
							</Col>
						</Row>
					</TabPane>
					<TabPane tabId={tabLabels.GROUP_REQUESTS}>
						<Row>
							<Col sm="12">
								<MemberRequests
									data={patientGroupRequests}
									role={type}
									auth={auth}
									downloadFile={this.downloadFile}
									loading={isFetchingPatientGroupReq}
									isMobile={isMobile}
									signDoc={this.signDoc}
									fetchRequest={this.getRequest}
									viewPdf={this.viewPdf}
								/>
							</Col>
						</Row>
					</TabPane>
					<TabPane tabId={tabLabels.RX_REQUESTS}>
						<Row>
							<Col sm="12">
								<RxRequests
									data={patientRxRequests}
									role={type}
									loading={isFetchingPatientRXReq}
									updateRequest={this.updateRequest}
									isMobile={isMobile}
								/>
							</Col>
						</Row>
					</TabPane>
				</TabContent>
			);
		}

		if (type === 'admin') {
			if (activeTab === tabLabels.ENROLLMENT_REQUESTS) {
				if (!showInactiveEnroll) {
					enrollReq = enrollReq.filter((r) => r.isActive);
				}

				filters.forEach((filter) => {
					enrollReq = filter.callback(filter.name, filter.option, enrollReq);
				});

				if (clientSearch) {
					enrollReq = filterByLetter(enrollReq, 'requestorName', clientSearch);
				}
			} else if (activeTab === tabLabels.MEMBER_REQUESTS) {
				if (!showInactiveMember) {
					memberReq = memberReq.filter((r) => r.isActive);
				}

				filters.forEach((filter) => {
					memberReq = filter.callback(filter.name, filter.option, memberReq);
				});

				if (clientSearch) {
					memberReq = filterByLetter(memberReq, 'requestorName', clientSearch);
				}
			}

			tabcontent = (
				<TabContent activeTab={activeTab}>
					<TabPane tabId={tabLabels.ENROLLMENT_REQUESTS}>
						<Row>
							<Col sm="12">
								<EnrollmentRequests
									data={enrollReq}
									role={type}
									downloadFile={this.downloadFile}
									updateRequest={this.updateRequest}
									deleteRequest={this.deleteRequest}
									auth={auth}
									clinicians={clinicians}
									changeClinician={this.changeClinician}
									loading={isFetchingEnrollReq}
									viewPdf={this.viewPdf}
								/>
							</Col>
						</Row>
					</TabPane>
					<TabPane tabId={tabLabels.MEMBER_REQUESTS}>
						<Row>
							<Col sm="12">
								<MemberRequests
									data={memberReq}
									role={type}
									auth={auth}
									downloadFile={this.downloadFile}
									deleteRequest={this.deleteRequest}
									updateRequest={this.updateRequest}
									clinicians={clinicians}
									changeClinician={this.changeClinician}
									loading={isFetchingMemberReq}
									isMobile={isMobile}
									viewPdf={this.viewPdf}
								/>
							</Col>
						</Row>
					</TabPane>
				</TabContent>
			);
		}
		return tabcontent;
	};

	goTo = (value) => {
		this.selectedGroupName = value;
		if (Object.getOwnPropertyNames(this.selectedGroupName).length !== 0) {
			this.handleRequestModal();
		}
	};

	toggleDropdown = () => {
		const { dropdownOpen } = this.state;
		this.setState({
			dropdownOpen: !dropdownOpen,
		});
	};

	addFilter = (filter) => {
		let { filters } = this.state;

		filters = filters.filter((x) => x.name !== filter.name);

		filters.push(filter);
		this.setState({ filters });
	};

	removeFilter = (name) => {
		this.setState((prevState) => ({
			filters: prevState.filters.filter((x) => x.name !== name),
		}));
	};

	generateFilters = () => {
		const { activeTab } = this.state;
		let {
			enrollReq,
			memberReq,
			patientGroupRequests,
			patientRxRequests,
			type,
		} = this.props;
		const options = {
			type,
		};

		if (!Array.isArray(enrollReq)) {
			enrollReq = [];
		}

		if (!Array.isArray(memberReq)) {
			memberReq = [];
		}
		if (!Array.isArray(patientGroupRequests)) {
			patientGroupRequests = [];
		}
		if (!Array.isArray(patientRxRequests)) {
			patientRxRequests = [];
		}

		if (activeTab === tabLabels.ENROLLMENT_REQUESTS) {
			options.groupName = getFilterOptions(enrollReq, 'groupName');
			options.location = getFilterOptions(enrollReq, 'location');
			options.clinicianName = getFilterOptions(enrollReq, 'clinicianName');
			if (enrollReq.length > 0) {
				options.minDate = enrollReq[enrollReq.length - 1].createdAt;
				options.maxDate = enrollReq[0].createdAt;
			}
		} else if (activeTab === tabLabels.GROUP_REQUESTS) {
			options.requestType = getFilterOptions(
				patientGroupRequests,
				'requestType',
			);
			options.name = getFilterOptions(
				patientGroupRequests,
				'payload.assignedClinician.name',
			);
			if (patientGroupRequests.length > 0) {
				options.minDate =
					patientGroupRequests[patientGroupRequests.length - 1].createdAt;
				options.maxDate = patientGroupRequests[0].createdAt;
			}
		} else if (activeTab === tabLabels.RX_REQUESTS) {
			options.clinicianName = getFilterOptions(
				patientRxRequests,
				'payload.clinician.name',
			);
			if (patientRxRequests.length > 0) {
				options.minDate =
					patientRxRequests[patientRxRequests.length - 1].createdAt;
				options.maxDate = patientRxRequests[0].createdAt;
			}
		} else if (activeTab === tabLabels.MEMBER_REQUESTS) {
			options.name = getFilterOptions(
				memberReq,
				'payload.assignedClinician.name',
			);
			options.requestType = getFilterOptions(memberReq, 'requestType');
			options.requestorName = getFilterOptions(memberReq, 'requestorName');
			if (memberReq.length > 0) {
				options.minDate = memberReq[memberReq.length - 1].createdAt;
				options.maxDate = memberReq[0].createdAt;
			}
		}
		return requestFilters(options, activeTab);
	};

	filterByDate = (name, option, data) => {
		const filtersToTabs = [
			'dateRange-enroll',
			'dateRange-group',
			'dateRange-rx',
			'dateRange-member',
		];
		const { activeTab } = this.state;
		if (!name.includes(filtersToTabs[activeTab - 1])) return data;

		const date = moment(option);

		return data.filter((r) =>
			name.includes('from')
				? moment(r.createdAt).isSameOrAfter(date.startOf('day'))
				: moment(r.createdAt).isSameOrBefore(date.endOf('day')),
		);
	};

	filterByLetter = (name, value) => {
		const { activeTab } = this.state;
		if (
			activeTab === tabLabels.ENROLLMENT_REQUESTS ||
			activeTab === tabLabels.ENROLLMENT_REQUESTS
		) {
			const { enrollReq } = this.props;
			const filteredData = filterByLetter(enrollReq, name, value);
			this.setState({
				enrollReq: filteredData,
			});
		} else if (activeTab === tabLabels.MEMBER_REQUESTS) {
			const { memberReq } = this.props;
			let path = '';
			if (name === 'name') {
				path = 'payload.assignedClinician.name';
			} else {
				path = name;
			}
			const filteredData = filterByLetter(memberReq, path, value);
			this.setState({
				memberReq: filteredData,
			});
		} else if (activeTab === tabLabels.GROUP_REQUESTS) {
			const { patientGroupRequests } = this.props;
			let path = '';
			if (name === 'name') {
				path = 'payload.assignedClinician.name';
			} else {
				path = name;
			}
			const filteredData = filterByLetter(patientGroupRequests, path, value);
			this.setState({
				patientGroupRequests: filteredData,
			});
		} else if (activeTab === tabLabels.RX_REQUESTS) {
			const { patientRxRequests } = this.props;
			let path = '';
			if (name === 'clinicianName') {
				path = 'payload.clinician.name';
			}
			const filteredData = filterByLetter(patientRxRequests, path, value);
			this.setState({
				patientRxRequests: filteredData,
			});
		}
	};

	filterDropdown = (name, value, data) => {
		const { activeTab } = this.state;
		if (
			activeTab === tabLabels.ENROLLMENT_REQUESTS ||
			activeTab === tabLabels.ENROLLMENT_REQUESTS
		) {
			return filterData(data, name, value);
		} else if (activeTab === tabLabels.MEMBER_REQUESTS) {
			let path = '';
			if (name === 'name') {
				path = 'payload.assignedClinician.name';
			} else {
				path = name;
			}

			return filterData(data, path, value);
		} else if (activeTab === tabLabels.GROUP_REQUESTS) {
			let path = '';
			if (name === 'name') {
				path = 'payload.assignedClinician.name';
			} else {
				path = name;
			}

			return filterData(data, path, value);
		} else if (activeTab === tabLabels.RX_REQUESTS) {
			let path = '';
			if (name === 'clinicianName') {
				path = 'payload.clinician.name';
			}

			return filterData(data, path, value);
		}
	};

	changeClinician = (data) => {
		const { dispatch } = this.props;
		data.callback = () => {
			const { activeTab } = this.state;

			if (activeTab === tabLabels.ENROLLMENT_REQUESTS) {
				dispatch(fetchEnrollRequests());
			} else {
				dispatch(fetchMemberRequests());
			}
		};
		dispatch(changeClinician(data));
	};

	requestsButton = () => {
		const { programs, isMobile } = this.props;
		const { dropdownOpen } = this.state;

		let buttonDropdownStyle = {};
		let menuStyle = {
			position: 'absolute',
			willChange: 'transform',
			top: '0px',
			left: '0px',
			transform: 'translate3d(-225px, 38px, 0px)',
		};

		if (isMobile) {
			buttonDropdownStyle = {
				position: 'absolute',
				bottom: 'calc(64px + 1rem)',
				right: '0.25rem',
			};
			menuStyle = {
				position: 'absolute',
				willChange: 'transform',
				top: '-380px',
				left: '80px',
				transform: 'translate3d(-225px, 38px, 0px)',
			};
		}

		return (
			<ButtonDropdown
				direction={`${isMobile ? 'up' : 'down'}`}
				isOpen={dropdownOpen}
				toggle={this.toggleDropdown}
				style={buttonDropdownStyle}
			>
				<DropdownToggle
					className={`btn btn-primary btn-color font-${
						isMobile ? '16 mobile-button' : '10'
					} mr-2 mt-1`}
					style={{
						width: !isMobile ? 'auto' : dropdownOpen ? '150px' : '40px',
						transition: '0.3s all ease-in-out',
						borderRadius: !isMobile ? '0px' : '20px',
						whiteSpace: 'nowrap',
						overflow: 'hidden',
					}}
				>
					<img src={Add} alt="" className="mr-2" />
					{isMobile && !dropdownOpen ? '' : 'NEW REQUEST'}
				</DropdownToggle>
				<DropdownMenu
					className={`${isMobile ? 'mb-1' : 'mb-3'}`}
					flip={false}
					style={menuStyle}
				>
					{programs.length === 0 ? (
						<DropdownItem
							disabled
							onClick={this.selectGroup}
							className="py-3 px-4 font-15 text-dark"
						>
							<div className="py-1 px-1 font-15 text-dark">
								Request for Leave of Absence
								<br />
								<div className="form-font">No Enrolled Therapies</div>
							</div>
						</DropdownItem>
					) : (
						<DropdownItem
							onClick={this.selectGroup}
							className="request-button py-3 px-4 font-15 text-dark"
						>
							Request for Leave of Absence
						</DropdownItem>
					)}

					<DropdownItem
						onClick={this.selectGroup}
						className="request-button py-3 px-4 font-15 text-dark"
					>
						Request for Release of Information
					</DropdownItem>

					<DropdownItem
						onClick={this.selectGroup}
						className="request-button py-3 px-4 font-15 text-dark"
					>
						Request for Rx
					</DropdownItem>
					<DropdownItem
						onClick={this.selectGroup}
						className="request-button py-3 px-4 font-15 text-dark"
					>
						General Request
					</DropdownItem>
					<DropdownItem
						onClick={this.selectGroup}
						className="request-button py-3 px-4 font-15 text-dark"
					>
						Request Telehealth
					</DropdownItem>
					<DropdownItem
						onClick={this.selectGroup}
						className="request-button py-3 px-4 font-15 text-dark"
					>
						Request for Discontinuance
					</DropdownItem>
				</DropdownMenu>
			</ButtonDropdown>
		);
	};

	toggleTabDropdown = () => {
		this.setState({ tabDropdownOpen: !this.state.tabDropdownOpen });
	};

	getDropdownLabel = (activeTab, type) => {
		let label;
		if (type === 'admin') {
			label = adminLabels.find((label) => label.id.toString() === activeTab);
		} else {
			label = patientLabels.find((label) => label.id.toString() === activeTab);
		}

		return label ? label.name : 'No Options';
	};

	searchForClient = (value) => {
		if (this.props.type === 'patient') return null;

		const {
			enrollReq,
			memberReq,
			clientSearch = value,
			activeTab,
		} = this.state;

		let dropdownList = [];

		if (activeTab === tabLabels.ENROLLMENT_REQUESTS) {
			dropdownList = enrollReq?.map((x) => ({
				label: x.requestorName,
				value: x.requestorName,
			}));
		} else if (activeTab === tabLabels.MEMBER_REQUESTS) {
			dropdownList = memberReq?.map((x) => ({
				label: x.requestorName,
				value: x.requestorName,
			}));
		}

		if (!Array.isArray(dropdownList)) {
			dropdownList = [];
		}

		dropdownList = dropdownList.filter((x) => x.label);
		dropdownList = uniqBy(dropdownList, (x) => x.label);
		dropdownList = dropdownList.sort((a, b) =>
			compareWords(a.label, b.label, 'asc'),
		);

		return (
			<SearchBar
				value={clientSearch}
				onSearch={(value) => {
					this.setState({
						clientSearch: value,
					});
				}}
				style={{
					searchBox: { height: 52 },
				}}
				dropdownList={dropdownList}
			/>
		);
	};

	render() {
		const {
			filterClicked,
			activeTab,
			showSelectGroup,
			loadRequestModal,
			dropdownItemSelected,
			loadRequestSentModal,
			modal,
			siteUrl,
			loading,
			signingError,
			filters,
			showPdfViewer,
			activeFile,
			showDeleteRequestModal,
		} = this.state;
		const {
			type,
			programs,
			pharmacy,
			requestStatus,
			patientRegister,
			auth,
			states,
			cities,
			zipcodes,
			isMobile,
			dispatch,
			file,
			isLoadingFile,
		} = this.props;
		const message = requestStatus
			? 'Your request was sent successfully'
			: 'Your request submission failed.';

		return (
			<div
				className={`container-fluid ${isMobile ? 'px-2 mt-3' : 'px-5 mt-5'}`}
			>
				{loading && <LoadingIndicator />}
				{signingError && (
					<StatusModal
						successStatus={false}
						hide={this.closeRequestSentModal}
						message="Something went wrong. Please try again."
					/>
				)}
				<NavTab
					type={type}
					getLabel={this.getDropdownLabel}
					activeTab={activeTab}
					handleChange={this.handleChange}
					isMobile={isMobile}
					labels={{
						admin: adminLabels,
						clinician: adminLabels,
						patient: patientLabels,
					}}
					switchTab={this.toggle}
				/>
				{filterClicked && (
					<FilterNav
						filtersList={this.generateFilters()}
						filterByLetter={this.filterByLetter}
						filterDropdown={this.filterDropdown}
						filterByDate={this.filterByDate}
						addFilter={this.addFilter}
						removeFilter={this.removeFilter}
						currentFilters={filters}
						isMobile={isMobile}
						dispatch={dispatch}
						search={this.searchForClient()}
						handleCheck={this.handleCheck}
						hasTabs
					/>
				)}
				{type === 'patient' && !isMobile && (
					<div className="card p-0 mt-2 mb-2">
						<div className="d-flex align-items-center justify-content-between p-3">
							<span className="font-weight-bold font-13 text-dark">
								List of Requests
							</span>
							<div className="d-flex justify-content-end">
								{this.requestsButton()}
							</div>
						</div>
					</div>
				)}
				<div className="pb-4">{this.getTabContent(activeTab, type)}</div>
				{showSelectGroup && (
					<SelectGroup
						hide={this.hideSelectGroup}
						goTo={this.goTo}
						programs={programs}
					/>
				)}
				{loadRequestModal && (
					<PatientRequestModal
						showSelectGroup={this.selectGroup}
						pharmacy={pharmacy}
						states={states}
						cities={cities}
						zipcodes={zipcodes}
						hide={this.closeRequestModal}
						groupObject={this.selectedGroupName}
						requestType={dropdownItemSelected}
						submitRequest={this.handleRequestSentModal}
						patientRegisterData={patientRegister}
						handleLoader={this.handleLoader}
						auth={auth}
						fetchCities={(selectedItem) =>
							dispatch(fetchCities(selectedItem.value, selectedItem.label))
						}
						fetchZipcodes={(selectedItem) =>
							dispatch(fetchZipcodes(selectedItem.value, selectedItem.label))
						}
						programs={programs}
						isMobile={isMobile}
					/>
				)}
				{loadRequestSentModal && (
					<RequestSentModal
						hide={this.closeRequestSentModal}
						successStatus={requestStatus}
						message={message}
						groupObject={this.selectedGroupName}
						requestType={dropdownItemSelected}
					/>
				)}
				{showDeleteRequestModal && (
					<ValidateSentModal
						hide={() =>
							this.setState({
								showDeleteRequestModal: false,
								activeRequest: null,
							})
						}
						handleDelete={this.handleDeleteRequest}
						message="You are deleting this request. This action is PERMANENT and CANNOT be undone."
					/>
				)}
				{showPdfViewer && (
					<PdfViewer
						isOpen={showPdfViewer}
						toggle={this.togglePdfViewer}
						file={file}
						documentData={activeFile}
						isLoading={isLoadingFile}
					/>
				)}
				{isMobile && (type === 'patient' || !isProduction) && (
					<div
						className="request-button"
						style={{ position: 'fixed', bottom: 0, right: 0 }}
					>
						{this.requestsButton()}
					</div>
				)}
				<SigningModal
					isOpen={modal}
					siteUrl={siteUrl}
					toggle={this.signingToggle}
				/>
			</div>
		);
	}
}
Requests.propTypes = {
	type: PropTypes.string.isRequired,
	enrollReq: PropTypes.array,
	programs: PropTypes.array,
	dispatch: PropTypes.func,
	auth: PropTypes.object,
	memberReq: PropTypes.array,
	pharmacy: PropTypes.object,
	requestStatus: PropTypes.bool,
	patientGroupRequests: PropTypes.array,
	patientRxRequests: PropTypes.array,
	fetchUserData: PropTypes.func,
	patientRegister: PropTypes.object,
	submitRoiData: PropTypes.func,
	siteUrl: PropTypes.string,
	clearSiteUrl: PropTypes.func,
	states: PropTypes.array,
	cities: PropTypes.array,
};
const mapStateToProps = createStructuredSelector({
	programs: makeSelectPrograms(),
	auth: makeSelectAuth(),
	enrollReq: makeSelectEnrollReq(),
	isFetchingEnrollReq: makeSelectIsFetchingEnrollReq(),
	memberReq: makeSelectMemberReq(),
	isFetchingMemberReq: makeSelectIsFetchingMemberReq(),
	pharmacy: makeSelectPharmacy(),
	patientGroupRequests: makeSelectPatientGroupRequests(),
	isFetchingPatientGroupReq: makeSelectIsFetchingPatientGroupReq(),
	patientRxRequests: makeSelectRxRequests(),
	isFetchingPatientRXReq: makeSelectIsFetchingPatientRXReq(),
	requestStatus: makeSelectRequestStatus(),
	patientRegister: makeSelectRoiData(),
	siteUrl: makeSelectSigningUrl(),
	states: makeSelectStates(),
	cities: makeSelectCities(),
	zipcodes: makeSelectZipcodes(),
	clinicians: makeSelectClinicians(),
	changedClinician: makeSelectChangedClinician(),
	isMobile: makeSelectIsMobile(),
	isLoadingFile: makeSelectIsLoadingFile(),
	file: makeSelectFile(),
});
function mapDispatchToProps(dispatch) {
	return {
		dispatch,
		getRequest: (id) => dispatch(fetchRequest(id)),
		fetchUserData: (data) => dispatch(loadPatientRoiData(data)),
		submitRoiData: (data) => dispatch(submitPatientRoiData(data)),
		clearSiteUrl: () => dispatch(clearUrl()),
		requestTelehealth: (data) => dispatch(requestTelehealth(data)),
		loadClinicians: () => dispatch(loadClinicians()),
		signDocument: (id) => dispatch(signDoc(id)),
		viewPdf: (uuid, cb) => dispatch(viewPdf(uuid, cb)),
		fetchPharmacy: (id) => dispatch(fetchPharmacy(id)),
	};
}
export default connect(mapStateToProps, mapDispatchToProps)(Requests);
