import history from 'utils/history';

import moment from 'moment';

import PropTypes from 'prop-types';

import {React, useState} from 'react';

/**
 *
 * <SelectButtonGroup />, a functional component to display buttons with single select option.
 *
 */
import {
	ButtonDropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from 'reactstrap';

import Table from 'atoms/Table';
import { headerStyle } from 'containers/App/constants';

const PatientTherapiesHistory = ({ data, loading, type, toggleAddGroup, updateEnrollment }) => {
	const [showOptions, setShowOptions] = useState(false);
	const [programsSelected, setProgramsSelected] = useState([]);
	const [allSelected, setAllSelected] = useState(false);

	const toggle = () => {
		setShowOptions(!showOptions);
	};

	const updateEnrollmentHelper = () => {
		updateEnrollment(programsSelected);

		setProgramsSelected([]);
		setAllSelected(false);
		selectNone();
	};

	const handleSelect = (id) => {
		if (programsSelected.includes(id)) {
			setProgramsSelected(programsSelected.filter((item) => item !== id));
		} else {
			setProgramsSelected([...programsSelected, id]);
		}
	};

	const selectNone = () => {
		const checkboxes = document.getElementsByName('selectbox');
		for (const checkbox of checkboxes) {
			checkbox.checked = false;
		}
	};

	const handleSelectAll = () => {
		if (!allSelected) {
			setProgramsSelected(data.map((program) => program.ptpId));
		}

		const checkboxes = document.getElementsByName('selectbox');
		for (const checkbox of checkboxes) {
			checkbox.checked = !allSelected;
		}

		setAllSelected(!allSelected);
	};

	const getColumns = () => {
		const cols = [
			{
				field: 'program_id',
				headerStyle,
				width: '75px',
				title: (
					<div className="mt-2 ml-2 font-13">
						<input
							className="hand custom-control custom-checkbox"
							type="checkbox"
							name="selectbox"
							style={{
								width: '1.25em',
								height: '1.25em',
							}}
							onClick={() => handleSelectAll()}
						/>
					</div>
				),
				render: (row) => (
					<div className="mt-2 mx-2 font-13">
						<input
							className="hand custom-control custom-checkbox"
							type="checkbox"
							name="selectbox"
							style={{
								width: '1.25em',
								height: '1.25em',
							}}
							onClick={() => handleSelect(row.ptpId)}
						/>
					</div>
				),
				sorting: false,
			},
			{
				field: 'groupName',
				title: 'THERAPY',
				headerStyle,
				width: '280px',
				render: (row) => (
					<div
						className=" mt-2 mx-2 font-13 link-color hand"
						onClick={() =>
							history.push({
								pathname: `/${type}/dashboard/groupDetails/${row.program_id}`,
							})
						}
					>
						{row.groupName}
					</div>
				),
			},
			{
				field: 'location',
				title: 'LOCATION',
				headerStyle,
				render: (row) => (
					<div className=" mt-2 mx-2 font-13">
						{row.location} -
						<br />
						{row.lastSession &&
							`${moment.utc(row.lastSession).local().format('h:mma')} ${moment
								.utc(row.lastSession)
								.local()
								.format('ddd')}`}
					</div>
				),
			},
			{
				field: 'startDate',
				title: 'START DATE',
				width: '200px',
				headerStyle,
				render: (row) => (
					<div className=" mt-2 mx-2 font-13" cursor="pointer">
						{row.startDate
							? moment.utc(row.startDate).local().format('MM-DD-YYYY')
							: '-'}
					</div>
				),
			},
			{
				field: 'endDate',
				title: 'END DATE',
				headerStyle,
				render: (row) => (
					<div className=" mt-2 mx-2 font-13" cursor="pointer">
						{row.endDate
							? moment.utc(row.endDate).local().format('MM-DD-YYYY')
							: '-'}
					</div>
				),
			},
			{
				field: 'program_id',
				headerStyle: {
					...headerStyle,
					color: '#999999',
				},
				title: (
					<div>
						<ButtonDropdown isOpen={showOptions} toggle={toggle}>
							<DropdownToggle
								className="btn btn-primary btn-color font-12 mr-2 mt-10 btn btn-secondary"
								caret
							>
								Options
							</DropdownToggle>
							<DropdownMenu
								flip={false}
								style={{
									transform: 'translate3d(-127px, 0px, 0px)',
								}}
							>
								<DropdownItem onClick={toggleAddGroup}>
									Add to Group
								</DropdownItem>
								<DropdownItem
									disabled={programsSelected.length === 0}
									onClick={() => updateEnrollmentHelper()}
								>
									Update Enrollment
								</DropdownItem>
							</DropdownMenu>
						</ButtonDropdown>
					</div>
				),
				render: () => <div></div>,
				sorting: false,
			},
		];

		if (type === 'patient') {
			cols.splice(0, 1);
			cols.splice(cols.length - 1, 1);
		}

		return cols;
	};

	return (
		<div>
			<Table
				data={data}
				columns={getColumns()}
				loading={loading}
				pagination={true}
				showWhenEmpty
			/>
		</div>
	);
};

PatientTherapiesHistory.propTypes = {
	data: PropTypes.array.isRequired,
};

export default PatientTherapiesHistory;
