import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf';
import pdfjsWorker from 'pdfjs-dist/legacy/build/pdf.worker.entry';

import PropTypes from 'prop-types';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import ActionButton from 'atoms/ActionButton';
import SmallLoadingIndicator from 'atoms/SmallLoadingIndicator';
import { clearFile } from 'containers/App/actions';
import downloadImage from 'svgs/download';
import { getLogger } from 'utils/logger';

import { PageInput, ToolbarButton } from './style';

const logger = getLogger('atoms.PdfViewer');

export default function PdfViewer({
	isOpen,
	toggle,
	isLoading,
	file,
	style,
	documentData,
}) {
	const [marginLeft, setMarginLeft] = useState(0);
	const [marginTop] = useState('2rem');
	const [pageInputValue, setPageInputValue] = useState(1);
	const [scale] = useState(1.5);

	const containerRef = useRef();
	const canvasRef = useRef();

	pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
	const pdfData = atob(file);

	const [pdfRef, setPdfRef] = useState();
	const [currentPage, setCurrentPage] = useState(1);

	const resizeMargin = useCallback(() => {
		if (!containerRef.current || !canvasRef.current) return;

		const containerWidth = containerRef.current.getBoundingClientRect().width;
		setMarginLeft(Math.max((containerWidth - canvasRef.current.width) / 2, 50));
	}, [containerRef, canvasRef, setMarginLeft]);

	const renderPage = useCallback(() => {
		pdfRef &&
			pdfRef.getPage(currentPage).then(function (page) {
				const viewport = page.getViewport({ scale });
				const canvas = canvasRef.current;
				canvas.height = viewport.height;
				canvas.width = viewport.width;

				resizeMargin();

				const renderContext = {
					canvasContext: canvas.getContext('2d'),
					viewport,
				};
				page.render(renderContext);
			});
	}, [pdfRef, currentPage]);

	useEffect(renderPage, [pdfRef, currentPage, renderPage]);

	useEffect(() => {
		const loadingTask = pdfjsLib.getDocument({ data: pdfData });
		loadingTask.promise.then(
			(loadedPdf) => {
				setPdfRef(loadedPdf);
			},
			function (reason) {
				logger.error(reason);
			},
		);
	}, [file]);

	const nextPage = () =>
		pdfRef &&
		currentPage < pdfRef.numPages &&
		handlePageChange(currentPage + 1);

	const prevPage = () => currentPage > 1 && handlePageChange(currentPage - 1);

	const inRange = (num) => {
		return num >= 1 && num <= pdfRef?.numPages;
	};

	const handleInputChange = (e) => {
		const {
			target: { value },
		} = e;

		const num = parseInt(value);

		if (value !== '' && (!num || !inRange(num))) return;

		setPageInputValue(value);

		if (num) {
			handlePageChange(num);
		}
	};

	const handlePageChange = (num) => {
		if (!inRange(num)) return;

		setCurrentPage(parseInt(num));
		setPageInputValue(num);
	};

	const handleToggle = () => {
		clearFile();
		toggle();
	};

	const doDownloadFile = () => {
		if(!pdfData) return;
		const len = pdfData.length;
		const bytes = new Uint8Array(len);

		for (let i = 0; i < len; i++) {
			bytes[i] = pdfData.charCodeAt(i);
		}
		const blob = new Blob([bytes], { type: 'application/pdf'});
		const url = URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', documentData.filename);
		document.body.appendChild(link);
		link.click();
	};

	const popupStyle = {
		background: 'var(--main-light-grey)',
	};

	return (
		<Modal
			isOpen={isOpen}
			className="modal-dialog-centered wide-title"
			backdrop="static"
			size={'xl'}
			style={{
				overflow: 'hidden',
				height: 'calc(100% - 0.5rem)',
				margin: '0.25rem auto',
			}}
		>
			<ModalHeader
				toggle={handleToggle}
				className="px-4 pb-0 border-0 container-fluid w-100"
			>
				View {documentData.filename || 'PDF'}
			</ModalHeader>
			<ModalBody
				className={'px-4 pt-0'}
				style={{
					height: 'auto',
				}}
			>
				{file && (
					<div
						style={{
							overflow: 'visible',
							background: 'white',
							border: '1px solid rgba(0, 0, 0, 0.25)',
							position: 'relative',
						}}
						className="row p-1 mx-0 justify-content-center"
					>
						<div style={{ position: 'absolute', left: '4px' }}>
							<ToolbarButton>
								<ActionButton
									image={downloadImage}
									alt="Download"
									onClick={doDownloadFile}
									popupStyle={{
										...popupStyle,
										top: -5,
									}}
								/>
							</ToolbarButton>
						</div>
						<div className="col-auto">
							<div className="row h-100 align-content-center">
								<div
									className="hand"
									onClick={prevPage}
									style={{
										width: 20,
										height: 40,
									}}
								>
									<span
										className="custom-arrow left"
										style={{
											marginTop: 13,
											borderColor:
												currentPage === 1
													? 'var(--main-light-grey)'
													: 'var(--main-color)',
										}}
									/>
								</div>
								<PageInput
									className="no-spinner bg-white mr-2 px-1 text-right"
									type="number"
									min={1}
									max={pdfRef?.numPages || 1}
									value={pageInputValue}
									onChange={handleInputChange}
									style={{
										color: 'black',
									}}
								/>
								<div
									className="mr-2"
									style={{
										minWidth: 28,
										marginTop: 5,
									}}
								>
									/ {pdfRef?.numPages}
								</div>
								<div
									className="hand"
									onClick={nextPage}
									style={{
										width: 20,
										height: 40,
									}}
								>
									<span
										className="custom-arrow right"
										style={{
											marginTop: 13,
											borderColor:
												currentPage === pdfRef?.numPages
													? 'var(--main-light-grey)'
													: 'var(--main-color)',
										}}
									/>
								</div>
							</div>
						</div>
					</div>
				)}
				<div
					style={{
						background: 'grey',
						width: '100%',
						height: window.innerHeight - 120,
						overflow: 'auto',
					}}
				>
					{isLoading ? (
						<div>
							<SmallLoadingIndicator text="Loading document" />
						</div>
					) : file ? (
						<div
							style={{
								...style,
								overflow: 'auto',
								position: 'relative',
							}}
							ref={containerRef}
						>
							<canvas
								ref={canvasRef}
								style={{
									marginLeft,
									marginRight: marginLeft,
									marginTop,
									marginBottom: marginTop,
								}}
							/>
						</div>
					) : (
						<div className="w-100 font-24 text-white text-center mt-3">
							There was an error loading your document
						</div>
					)}
				</div>
			</ModalBody>
		</Modal>
	);
}

PdfViewer.propTypes = {
	file: PropTypes.object.isRequired,
	isLoading: PropTypes.bool,
	style: PropTypes.object,
};
